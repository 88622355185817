import { createTheme } from "@mui/material/styles"

import "./../static/fonts/fonts.css"

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#0057FF",
    },
    secondary: {
      main: "#222222",
      blue: "#0870E0",
    },
    background: {
      chip: "#e0e0e0",
      paper: "white",
      paper2: "white",
      paperChip: "#E8F1F9",
      footer: "white",
      white: "#FFF",
      grey: "#F8F8F8",
    },
    linkColor: {
      color: "#FFF",
    },
    selection: {
      color: "#ccd0d3",
      color2: "#ccd0d3",
    },
  },
  typography: {
    h1: {
      // fontSize: "52px",
      fontFamily: "'Heiti TC', sans-serif",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "3.2vw",
      background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
      webkitBackgroundClip: "text",
      webkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      "@media (max-width:1024px)": {
        fontSize: "6vw",
      },
      "@media (max-width:576px)": {
        fontSize: "8vw",
      }
    },
    h2: {
      fontFamily: "'Heiti TC', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "45px",
      lineHeight: "120%",

      letterSpacing: "-0.01em",

      background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
      webkitBackgroundClip: "text",
      webkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      "@media (max-width:1024px)": {
        fontSize: "5vw",
      },
      "@media (max-width:576px)": {
        fontSize: "7vw",
        // paddingLeft: "4vw",
      },
    },
    h3: {
      color: "#001224",
      fontWeight: 700,
      fontSize: "2.2vw",
    },
    h4: {
      fontFamily: "'Heiti TC', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "120%",
      wordWrap: "break-word",

      letterSpacing: "-0.01em",

      background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
      webkitBackgroundClip: "text",
      webkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      "@media (max-width:1024px)": {
        fontSize: "5vw",
      },
      "@media (max-width:576px)": {
        fontSize: "7vw",
        // paddingLeft: "4vw",
      },
    },
    h5: {
      color: "#1DA1F2",
      fontWeight: 600,
      fontSize: "3vw",
      fontStyle: "normal",
    },
    h6: {
      fontSize: "1vw",
      fontWeight: 600,
      color: "#001224",
    },
    body1: {
      marginRight: 10
    },
    body2: {
      fontSize: "1.5vw",
      color: "#001224",
      opacity: 0.4,
      fontWeight: 200,
    },
    subtitle1: {
      fontSize: "1.5vw",
      color: "#001224",
      fontWeight: 400,
    },
    subtitle2: {
      color: "#001224",
      fontSize: "2vw",
      fontWeight: 700,
    },
    globalTeam: {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "190%",
      color: "#222",
      textAlign: "center",
      display: "inline-block",
      "@media (max-width:1024px)": {
        fontSize: "2.4vw",
      },
      "@media (max-width:576px)": {
        fontSize: "4.6vw",
      },
    },
    globalTeamMark: {
      color: "#3e94f1",
    },
    globalTeamName: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "190%",
      color: "#0870E0",
      textAlign: "center",
      display: "inline-block",
      whiteSpace: "nowrap",
      "@media (max-width:1024px)": {
        fontSize: "1.8vw",
        lineHeight: "100%",
      },
      "@media (max-width:576px)": {
        fontSize: "3.6vw",
      },
    },

    globalTeamPosition: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "130%",
      color: "#222",
      textAlign: "center",
      display: "inline-block",
      "@media (max-width:1024px)": {
        fontSize: "1.5vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3.1vw",
      },
    },
    portfolioButton: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      verticalAlign: "middle",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "130%",
      color: "#FFF",
      textAlign: "center",
      textTransform: "uppercase",
      "@media (max-width:1024px)": {
        fontSize: "1.8vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3.1vw",
      },
    },
    portfolioCompanyButton: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      verticalAlign: "middle",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "100%",
      letterSpacing: "-0.01em",
      textTransform: "uppercase",
      display:"flex",
      alignItems: "center",
      "@media (max-width:1025px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3vw",
      },
      "@media (max-width:390px)": {
        fontSize: "3.5vw",
      },
    },
    portfolioBody: {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "150%",
      color: "#222",
      "@media (max-width:1024px)": {
        fontSize: "1.8vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3.1vw",
      },
    },
    portfolioItem: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "16px",
      color: "#222",
      opacity: 0.5,
      whiteSpace: "nowrap",
      "@media (max-width:1024px)": {
        fontSize: "1.3vw",
      },
      "@media (max-width:576px)": {
        fontSize: "2.7vw",
      },
    },
    portfolioDescription: {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "150%",
      "@media (max-width:1024px)": {
        fontSize: "1.7vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3.1vw",
      },
    },
    linkInNavigation: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "16px",
      letterSpacing: "-0.01em",
      textTransform: "uppercase",
      // color: "#FFFFFF",
      backgroundImage: "linear-gradient(currentColor, currentColor)",
      backgroundPosition: "0% 100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "0% 1px",
      transition: "background-size .3s",
      "&:hover": {
        backgroundSize: "100% 1px",
      },
      "&:focus": {
        backgroundSize: "100% 1px",
      },
      "@media (max-width:1025px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3.33vw",
      },
    },
    investorsFoundersTitle: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "36px",
      lineHeight: "120%",
      textAlign: "center",
      letterSpacing: "-0.01em",
      color: "#FFF",
      // "@media (max-width:1300px)": {
      //   fontSize: "32px",
      // },
      // "@media (max-width:1025px)": {
      //   fontSize: "3vw",
      // },
      "@media (max-width:576px)": {
        fontSize: "6.15vw",
      },
    },
    investorsFoundersButton: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "16px",
      textTransform: "uppercase",
      color: "#FFF",
      // "@media (max-width:1025px)": {
      //   fontSize: "1.6vw",
      // },
      // "@media (max-width:576px)": {
      //   fontSize: "2.4vw",
      // },
      "@media (max-width:576px)": {
        lineHeight: "103%",
        fontSize: "3.33vw",
      },
    },
    newsAndUpdates: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "-0.01em",
      fontSize: "18px",
      lineHeight: "18px",
      color: "#222",
      textTransform: "uppercase",
      "@media (max-width:1025px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width:576px)": {
        fontSize: "4.1vw",
      },
    },
    newsTitle: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "-0.01em",
      fontSize: "24px",
      lineHeight: "104%",
      color: "#0870E0",
      whiteSpace: "nowrap",
      "@media (max-width:1025px)": {
        fontSize: "2.2vw",
      },
      "@media (max-width:576px)": {
        fontSize: "4.6vw",
      },
    },
    newsBody: {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "150%",
      color: "#222",
      marginBottom: "21px",
      "@media (max-width:1025px)": {
        fontSize: "2.2vw",
      },
      "@media (max-width:576px)": {
        fontSize: "4.6vw",
        marginBottom: "20px",
      },
    },
    newsFooter: {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineGeight: "190%",
      color: "#222",
      opacity: 0.6,
      "@media (max-width:1025px)": {
        fontSize: "1.8vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3.3vw",
      },
    },
    textInNavigation: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      letterSpacing: "-0.01em",
      textTransform: "uppercase",
      "@media (max-width:576px)": {
        fontSize: "4.6vw",
      },
    },
    textSubscribeNewsletter: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "-0.01em",
      textTransform: "uppercase",
      userSelect: "none",
      "@media (max-width:1025px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width:576px)": {
        lineHeight: "3.58vw",
        fontSize: "3.58vw",
      },
    },
    textSubscribeNewsletterInButton: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "-0.01em",
      textTransform: "uppercase",
      "@media (max-width:1025px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width:576px)": {
        fontSize: "3.58vw",
      },
    },
    textInMobileMenu: {
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "5.8vw",
      letterSpacing: "-0.01em",
      textTransform: "uppercase",
    },
    copyright: {
      fontFamily: "Merriweather",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "150%",
      display: "flex",
      alignItems: "center",
      opacity: 0.5,
      color: "#FFFFFF",
      userSelect: "none",
      "@media (max-width:1025px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width:576px)": {
        fontSize: "2.82vw",
        lineHeight: "2.82vw",
      },
    },
  },
  buttons: {
    transparentButton: {
      border: "1px solid #FFFFFF",
      color: "#FFFFFF",
      boxSizing: "border-box",
      borderRadius: "2px",
      backgroundColor: "transparent",

      // all: "unset",
      cursor: "pointer",
      height: "42px",
      "&:focus": {
        outline: "none",
      },
      "@media (max-width:1024px)": {
        height: "40px",
      },
    },
    ourPortfolio: {
      background: " #222222",
      borderRadius: "2px",
      cursor: "pointer",
      transition: "all 0.7s",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#0870E0",
      },
      "&:focus": {
        color: "#FFF",
        backgroundColor: "#0870E0",
      },
    },
    ourTeam: {
      border: "1px solid #0870E0",
      color: "#0870E0",
      boxSizing: "border-box",
      borderRadius: "2px",
      backgroundColor: "transparent",
      cursor: "pointer",
      height: "58px",
      width: "220px",
      fontFamily: "Heiti TC",
      fontStyle: "normal",
      // fontWeight: 400,
      fontSize: "18px",
      lineHeight: "19px",
      letterSpacing: "-0.01em",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.7s",
      padding: "2px 6px",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#0870E0",
      },
      "&:focus": {
        color: "#FFF",
        backgroundColor: "#0870E0",
      },
      "@media (max-width:576px)": {
        width: "182px",
        minHeight: "50px",
      },
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: "'Merriweather', sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "150%",
          maxWidth: "250px",
          backgroundColor: "transparent",
          color: "#FFF",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:focused": {
            backgroundColor: "transparent",
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: "#FFF",
            opacity: 0.5,
          },
          "&:hover:not(.Mui-focused):before": {
            borderBottomColor: "#FFF",
            opacity: 0.5,
          },
          "&:after": {
            opacity: 0.5,
            borderBottomColor: "#FFF",
          },
        },
      },
    },
    MuiCardActionArea: {
      root: {
        backgroundColor: "white",
      },
    },
  },
  shadows: [
    "none",
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
    "0px 4px 50px rgba(0, 0, 0, 0.1)",
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 1024,
    },
  },
})

export default theme
