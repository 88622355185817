import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./src/templates/theme.js"

export default function MuiRootWrapper({ element }) {
  try {
    return (
      <ThemeProvider theme={theme}>
          {element}
      </ThemeProvider>
    )
  } catch (e) {
    return (
      <ThemeProvider theme={theme}>
          {element}
      </ThemeProvider>
    )
  }
}
